<!--  -->
<template>
    <div>
        <el-dialog
            :visible.sync="dialogVisible"
            title="批阅作业"
            :close-on-click-modal="false"
            :width="imgList.length > 0 ? '812px' : '538px'"
        >
            <div class="work_pop">
                <div class="img_part" v-if="imgList.length > 0">
                    <img
                        :src="imgList[imgIndex] | imgFormat"
                        class="img_block"
                    />
                    <div class="ls_flex_center">
                        <i class="iconfont icon-zuo-01" @click="pre" />
                        <div>{{ imgIndex + 1 }}</div>
                        <i class="iconfont icon-you-01" @click="next" />
                    </div>
                </div>
                <div class="w_info">
                    <el-form
                        ref="dataForm"
                        :rules="rules"
                        :model="queryParams"
                        label-position="right"
                        label-width="70px"
                    >
                        <el-row>
                            <el-row v-if="imgList.length > 0">
                                <el-form-item label="3D动效" prop="status">
                                    <span class="opt_part">
                                        ：
                                        <span
                                            class="main_color"
                                            @click="toEditor"
                                        >
                                            查看效果图
                                        </span>
                                    </span>
                                </el-form-item>
                            </el-row>
                            <el-col :span="24">
                                <el-row>
                                    <el-form-item
                                        label="作业结果"
                                        prop="status"
                                    >
                                        <div
                                            v-if="type != 1"
                                            style="height: 40px"
                                            class="ls_flexalign_center"
                                        >
                                            <el-radio
                                                v-model="queryParams.status"
                                                label="2"
                                            >
                                                通过
                                            </el-radio>
                                            <el-radio
                                                v-model="queryParams.status"
                                                label="1"
                                            >
                                                重做
                                            </el-radio>
                                        </div>

                                        <span v-else>：通过</span>
                                    </el-form-item>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item
                                            label="评语"
                                            prop="schoolworkRemark"
                                        >
                                            <el-input
                                                :autosize="{
                                                    minRows: 7,
                                                }"
                                                v-model="
                                                    queryParams.schoolworkRemark
                                                "
                                                type="textarea"
                                                placeholder="请填写评语"
                                                maxlength="300"
                                                v-if="type != 1"
                                                resize="none"
                                                style="width: 340px"
                                            />
                                            <span
                                                v-if="type == 1"
                                                :title="
                                                    queryParams.schoolworkRemark ||
                                                    '--'
                                                "
                                                class="three-line"
                                            >
                                                <span>：</span>
                                                {{
                                                    queryParams.schoolworkRemark ||
                                                    '--'
                                                }}
                                            </span>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row></el-row>
                            </el-col>
                        </el-row>
                        <div class="dialog_foot" v-if="type != 1">
                            <el-button type="primary" @click="create">
                                确认批阅
                            </el-button>
                            <el-button @click="pageClose">取消</el-button>
                        </div>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            type: '',
            dialogVisible: false,
            rules: {},
            queryParams: {
                ids: [],
                status: '',
                schoolworkRemark: '',
            },
            imgList: [],
            imgIndex: 0,
            title: '批阅作业',
            productId: '',
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        pageClose() {
            this.dialogVisible = false;
            this.$emit('getList');
        },
        toEditor() {
            window.open(
                this.$editAddr +
                    '?type=examine&productId=' +
                    this.productId +
                    `&authToken=${localStorage.getItem('authToken')}`
            );
        },
        getResult(id) {
            this.$ajax
                .get('/schoolwork/student/getById', { params: { id } })
                .then(res => {
                    this.name = res.data.studentUserName;
                    this.title = res.data.schoolworkName;
                    this.imgList = res.data.schoolworkImages || [];
                    this.productId = res.data.productId;
                    this.$set(
                        this.queryParams,
                        'schoolworkRemark',
                        res.data.schoolworkRemark || ''
                    );

                    if (res.data.status == 2) {
                        // 待批阅状态
                        this.queryParams.status = 1;
                    }
                    this.dialogVisible = true;
                });
        },
        create() {
            if (this.queryParams.status === '') {
                this.$message.warning({
                    message: '请选择作业成绩',
                });
                return;
            }
            this.$ajax
                .post('/schoolwork/correct', this.queryParams)
                .then(res => {
                    this.$message({
                        message: '提交成功',
                        type: 'success',
                    });
                    this.pageClose();
                });
        },
        pre() {
            if (this.imgIndex === 0) {
                return;
            }

            this.imgIndex--;
        },
        next() {
            if (this.imgIndex === this.imgList.length - 1) {
                return;
            }
            this.imgIndex++;
        },
    },
};
</script>
<style lang="scss" scoped>
.work_pop {
    // padding: 20px;
    // width: 1160px;
    // height: 580px;
    background-color: #fff;
    position: relative;
    display: flex;

    .handlePosition {
        // width: 112px;
        background: #ffffff;
        border: 1px solid #dddddd;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        position: absolute;
        left: 600px;
        bottom: 40px;
        padding: 16px;
        z-index: 99;

        &.work {
            bottom: 50px;
        }

        .line {
            height: 1px;
            background: #e5e5e5;
        }

        .handleIcon {
            width: 28px;
            height: 28px;
            background: #f5f5f5;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background: #f56c0b;
                color: #fff;
            }
        }
    }

    .product-header {
        position: absolute;
        top: -1px;
        left: 20px;
        width: calc(100% - 40px);

        .product-title {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #333333;
        }

        .product-bnt {
            margin-right: 580px;
        }
    }
}

.w_info {
    // width: calc(100% - 770px);
    vertical-align: top;
    height: 100%;
    // overflow: auto;

    .viewBox {
        height: calc(100% - 80px);
        overflow: auto;
    }

    .share-bnt {
        margin-top: 10px;
    }

    .title {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #333333;
    }

    .view-title {
        font-size: 16px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        line-height: 24px;
        color: #333333;
    }

    .list {
        > div {
            display: flex;
            font-size: 14px;

            > div:first-child {
                color: #999999;
                margin-right: 16px;
            }

            div {
                line-height: 20px;
            }

            + div {
                margin-top: 24px;
            }
        }

        .desc-box {
            .open-bnt {
                width: 100%;
            }
        }
    }

    .materials {
        flex: 1;

        > div {
            display: flex;
        }

        > div + div {
            margin-top: 12px;
        }
    }
}

.img_part {
    margin-right: 15px;

    .img_block {
        width: 300px;
        height: 300px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: rgb(250, 250, 250);
        margin-bottom: 15px;
    }

    i {
        width: 40px;
        line-height: 40px;
        text-align: center;
        background: #8bb229;
        color: #fff;
        border-radius: 100%;
        cursor: pointer;
        font-size: 25px;
    }

    .ls_flex_center > div {
        width: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 25px;
    }
}

.dialog_foot {
    margin-left: 70px;
    // text-align: center;
}

.three-line {
    vertical-align: baseline;
    line-height: 40px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    padding-left: 14px;
    position: relative;
    width: 400px;

    > span {
        position: absolute;
        top: 13px;
        left: 0;
    }
}
</style>
